<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="安全生产事故情况报表"
      @head-save="headSave()"
    ></head-layout>
    <el-scrollbar>
      <div>
        <el-main>
          <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="170px"
                   class="demo-ruleForm form-container"
                   style="margin-top: 20px; width: 99%;">
            <el-row>
              <el-col :span="12">
                <el-form-item label="企业名称" prop="companyName">
                  <template slot="label">
                    <span>企业名称</span>
                  </template>
                  <el-input
                    :disabled="true"
                    v-model="dataForm.companyName"
                    controls-position="right"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="统计月份" prop="dateAndTime">
                  <el-date-picker
                    :disabled="true"
                    v-model="dataForm.dateAndTime"
                    type="month"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                    @change="chooseDate"
                    >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="custom-row" :gutter="20">
              <el-col :span="12">
                <el-form-item label="填报日期" prop="updateTime">
                  <template slot="label">
                    <span>填报日期:</span>
                  </template>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="dataForm.updateTime"
                    type="date"
                    disabled="true"
                    placeholder="保存后自动生成"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属集团二级企业编码" prop="parentUnitCode">
                  <el-input
                    v-model="dataForm.parentUnitCode"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属集团二级企业名称" prop="parentCompanyName">
                  <el-input
                    v-model="dataForm.parentCompanyName"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故发生起数" prop="accCnt">
                  <el-input
                    v-model="dataForm.accCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.accCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故死亡人数" prop="deathCnt">
                  <el-input
                    v-model="dataForm.deathCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.deathCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故重伤人数" prop="seriousInjuryCnt">
                  <el-input
                    v-model="dataForm.seriousInjuryCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.seriousInjuryCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="事故轻伤人数" prop="minorInjuryCnt">
                  <el-input
                    v-model="dataForm.minorInjuryCnt"
                    controls-position="right"
                    maxlength="8"
                    show-word-limit
                    :readonly="dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.minorInjuryCntS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="直接经济损失（万元）" prop="directEconomicLosses">
                  <el-input
                    v-model="dataForm.directEconomicLosses"
                    controls-position="right"
                    maxlength="10"
                    show-word-limit
                    :readonly="dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.directEconomicLossesS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="间接经济损失（万元）" prop="indirectEconomicLosses">
                  <el-input
                    v-model="dataForm.indirectEconomicLosses"
                    controls-position="right"
                    maxlength="10"
                    show-word-limit
                    :readonly="dataForm.dataState == '2' || dataForm.dataState == '4'"
                    style="width: calc(100% - 30px)"
                  ></el-input>
                  <el-tooltip class="item" effect="dark" :content="dataForm.indirectEconomicLossesS" placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-main>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {saveOrUpdate, detail, detailByBusiness} from "@/api/report/safeAccidentStat";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      rules: {
        dateAndTime: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        parentUnitCode: [
          {message: '所属集团二级企业编码为空', trigger: ['blur']},
        ],
        parentCompanyName: [
          {message: '所属集团二级企业名称为空', trigger: ['blur']},
        ],
        accCnt: [
          {required: true, message: '请输入事故发生起数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        deathCnt: [
          {required: true, message: '请输入事故死亡人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        seriousInjuryCnt: [
          {required: true, message: '请输入事故重伤人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        minorInjuryCnt: [
          {required: true, message: '请输入事故轻伤人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        directEconomicLosses: [
          // {required: true, message: '请输入直接经济损失（万元）', trigger: ['blur']},
          {pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位' },
        ],
        indirectEconomicLosses: [
          // {required: true, message: '请输入间接经济损失（万元）', trigger: ['blur']},
          {pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位' },
        ],
      },
      existingYearPeopleCnt: 0,
      existingYearOrgCnt: 0,
      type: 'view',
      dataForm: {},
      disabled: false,
      formLoading: false,
      processTemplateKey: "report_fill_safe_accident_stat",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let result = [];
      if (this.dataForm.dataState == 3) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
      }
      return result;
    },
  },
  created() {
    this.getProcessId();
    let {unitName, unitCode, type, id} = this.$route.query;
    this.type = type;
    this.dataForm.companyName = unitName
    this.dataForm.unitCode = unitCode
    this.dataForm.id = id
    this.initData()
  },
  async mounted() {
    let flowData = await detail(this.dataForm.id);
    if (flowData.data.data.dataState != 3) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.$forceUpdate();
  },
  methods: {
    chooseDate(e) {
      this.dataForm.dateAndTime = e
      if (e != null) {
        detailByBusiness(this.dataForm).then((res) => {
          const data = res.data.data
          if (data.id && data.id != -1) {
            this.dataForm = data
          } else {
            this.dataForm.id = null
            this.dataForm.dataState = null
            this.dataForm.processInsId = null
            this.dataForm.createName = null
            this.dataForm.updateName = null
          }
          if (data.existingYearPeopleCnt != -1) {
            this.existingYearPeopleCnt = data.existingYearPeopleCnt
          }
          if (data.existingYearOrgCnt != -1) {
            this.existingYearOrgCnt = data.existingYearOrgCnt
          }
        })
      }
    },
    initData() {
      detail(this.dataForm.id).then(res => {
        const item = res.data.data;
        if (item.dataState == -1) {
          item.dataState = 1
        }
        this.dataForm = item;
      })
    },
    headSave(cancel = false) {
      this.dataForm.dataSource = 'fill'
      if (!this.dataForm.id) {
        this.dataForm.dataState = 1
      }
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          this.$loading();
          saveOrUpdate(this.dataForm)
            .then(res => {
              const {msg, data} = res.data;
              this.dataForm = data
              this.$message({
                type: "success",
                message: "操作成功,数据已刷新"
              });
            }).finally(() => {
            this.$loading().close();
          })
        }
      })
    },
    headSubmit(row) {
      if (this.$refs.formLayout.dataForm.id) {
        this.$confirm("确定提交审核?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            var row = this.$refs.formLayout.dataForm;
            if (row.safeWorkHours == null || row.safeWorkHours < 0) {
              return this.$message({
                message: "请先进行填报",
                type: "warning",
              });
            }
            saveOrUpdate({
              ...this.$refs.formLayout.dataForm
            }).then((res) => {
              if (res.data.code === 200) {
                this.dataForm = res.data.data;
              }
            }).then(() => {
              // 页面全局Loading
              this.$loading();
              this.rpTaskAttributeSubmit(row);
            })
          }).finally(() => {
          this.$loading().close();
        })
      } else {
        this.$message.warning("请先保存数据");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.formProcess.id = data.id;
      this.formProcess.deptId = this.nodeId
      this.handleStartProcess5(true,true).then((res) => {
        this.dataForm.processInsId = res.data.data;
        this.dataForm.dataState = 2;
        saveOrUpdate(this.dataForm).then((res) => {
          if (res.data.code === 200) {
            this.$emit("closeDialog")
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.initData();
          }
        })
          .catch((err) => {
          })
          .finally(() => {
            this.$loading().close();
          });
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
